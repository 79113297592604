<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('add_project')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col
                                cols="12"
                        >
                            <v-text-field
                                    :label="$t('project_name')"
                                    v-model="projectData.projectName"
                                    :error-messages="projectNameErrors"
                                    required
                                          dense
                                          outlined
                                    @input="$v.projectData.projectName.$touch()"
                                    @blur="$v.projectData.projectName.$touch()"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose">{{$t("form.cancel")}}
            </v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t("form.save")}}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";
    import {required} from "vuelidate/lib/validators";

    export default {
        name: "ProjectForm",
        computed: {
            projectNameErrors() {
                const errors = [];
                if (!this.$v.projectData.projectName.$dirty) return errors;
                !this.$v.projectData.projectName.required &&
                errors.push("Project Name  is required");
                return errors;
            },
        },
        data() {
            return {
                projectData: {
                    ministryID: 1,
                    provinceID: parseInt(this.$route.params.provinceId),
                    divisionID: parseInt(this.$route.params.divisionId),
                },
                snackbar: {
                    dateMenu: false,
                    servicesData: {
                        data: {},
                        message: "",
                        color: ""
                    },
                }
            }
        },
        methods: {
            dialogueClose() {
                this.projectData = {};
                this.$emit("dialogueClose");
            },
            formResponse() {
                this.$emit("formResponse", this.snackbar.servicesData);
                this.dialogueClose();
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    axios.post("Survey/InsertIntoSurveyProjectAsync", this.projectData).then(response => {
                        if (response.data.success) {
                            this.snackbar.servicesData.data = response.data
                            this.snackbar.servicesData.message = "Project added Successfully"
                            this.snackbar.servicesData.color = "success"
                            this.formResponse()
                        }
                    }).catch(response => {
                        this.snackbar.servicesData.data = response.data
                        this.snackbar.servicesData.message = "Error Adding Project, Please Contact Admin"
                        this.snackbar.servicesData.color = "error"
                        this.formResponse()
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.projectData = {};
            },
        },
        mounted() {
        },
        validations: {
            projectData: {
                projectName: {required},
            }
        },
    }
</script>

<style scoped>

</style>