<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_project')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col
                                    cols="12"
                            >
                                <v-text-field
                                        :label="$t('project_name')"
                                        v-model="projectData.projectName"
                                        :error-messages="projectNameErrors"
                                        required
                                              dense
                                              outlined
                                        @input="$v.projectData.projectName.$touch()"
                                        @blur="$v.projectData.projectName.$touch()"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose">{{$t("form.cancel")}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t("form.save")}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";
    import {required} from "vuelidate/lib/validators";

    export default {
        name: "editProjectForm",
        props: ['editItemIndex'],
        computed: {
            projectNameErrors() {
                const errors = [];
                if (!this.$v.projectData.projectName.$dirty) return errors;
                !this.$v.projectData.projectName.required &&
                errors.push("Project Name  is required");
                return errors;
            },

        },
        data() {
            return {
                projectData: {},
                snackbar: {
                    dateMenu: false,
                    servicesData: {
                        data: {},
                        message: "",
                        color: ""
                    },
                }
            }
        },
        mounted() {
            this.formData()
        },
        methods: {
            dialogueClose() {
                this.projectData = {};
                this.$emit("dialogueClose");
            },
            formResponse() {
                this.$emit("formResponse", this.snackbar.servicesData);
                this.dialogueClose();
            },
            async formData() {
                let projectData = await axios.get("Survey/GetSurveyProjectByIDAsync/" + this.editItemIndex)
                this.projectData = projectData.data
            },
            submit() {
                this.projectData.divisionID = 3
                axios.post("Survey/UpdateSurveyProjectAsync", this.projectData).then(response => {
                    if (response.data.success) {
                        this.snackbar.servicesData.data = response.data
                        this.snackbar.servicesData.message = "Project Updated Successfully"
                        this.snackbar.servicesData.color = "success"
                        this.formResponse()
                    }
                }).catch(response => {
                    this.snackbar.servicesData.data = response.data
                    this.snackbar.servicesData.message = "Error Updating Project, Please Contact Admin"
                    this.snackbar.servicesData.color = "error"
                    this.formResponse()
                });

            },
            clear() {
                this.$v.$reset();
                this.projectData = {};
            },
        },
        validations: {
            projectData: {
                projectName: {required},
            }
        },
    };
</script>

<style scoped>
</style>